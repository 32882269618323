import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../App.css';
import usaFlag from '../pics/usa.png';
import euFlag from '../pics/eu.png';

interface ExchangeRate {
  date: string;
  rate: number;
}

const ExchangeRatesSidebar: React.FC = () => {
  const [euroRate, setEuroRate] = useState<number | null>(null);
  const [dollarRate, setDollarRate] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchExchangeRates = async () => {
      try {
        console.log('Fetching exchange rates...');
        const response = await axios.get('/api/exchange-rates');
        const rates: { [key: string]: ExchangeRate } = response.data;

        console.log('Exchange rates response:', rates);

        // Access EUR and USD rates directly
        if (rates.EUR) setEuroRate(rates.EUR.rate);
        if (rates.USD) setDollarRate(rates.USD.rate);
      } catch (error) {
        console.error('Error fetching exchange rates:', error);
        setError('Failed to fetch exchange rates');
      }
    };

    fetchExchangeRates();
  }, []);

  return (
    <div className="exchange-rates-sidebar">
      
      {error && <p className="exchange-rates-error">{error}</p>}
      {!error && (
        <div className="exchange-rates-content">
          <div className="exchange-rate-item">
          <img src={euFlag} alt="US Flag" className="flag-icon" />

            <span>{euroRate ? euroRate.toFixed(4) : 'Loading...'}</span>
          </div>
          <div className="exchange-rate-item">
          <img src={usaFlag} alt="US Flag" className="flag-icon" />

            <span>{dollarRate ? dollarRate.toFixed(4) : 'Loading...'}</span>
          </div>
        </div>
      )}
    </div>
  );
};

export default ExchangeRatesSidebar;
