import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import ContractForm from "./ContractsForm";
import Translate from "./Lang";

interface Client {
  Id: number;
  Denumire: string;
}

interface Organisation {
  Id: number;
  Denumire: string;
}

interface Contract {
  Id: number;
  OrganizationId: string;
  ClientId: string;
  Description: string;
  Amount: number;
  InvoicingDate: string;
}

interface ContractListProps {
  searchTerm: string;
}

const ContractList: React.FC<ContractListProps> = ({ searchTerm }) => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedContract, setSelectedContract] = useState<Contract | null>(null);

  const toggleForm = () => {
    setShowForm(!showForm);
    setSelectedContract(null);
  };

  const handleEditClick = (contract: Contract) => {
    setSelectedContract(contract);
    setShowForm(true);
  };

  const handleDeleteClick = async (contractId: number) => {
    const confirmed = window.confirm("Are you sure you want to delete this contract?");
    if (!confirmed) return;

    try {
      await axios.delete(`/api/contracts/${contractId}`);
      fetchContracts();
    } catch (error) {
      console.error("Error deleting contract:", error);
    }
  };

  const fetchContracts = async () => {
    setLoading(true);
    try {
      const contractResponse = await fetch("/api/contracts");
      const contractData = await contractResponse.json();
      setContracts(contractData);

      const clientResponse = await fetch("/api/clients");
      const clientData = await clientResponse.json();
      setClients(clientData);

      const organisationResponse = await fetch("/api/organisations");
      const organisationData = await organisationResponse.json();
      setOrganisations(organisationData);
    } catch (error: any) {
      console.error("Error fetching data:", error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContracts();
  }, []);

  const handleFormSubmitSuccess = () => {
    fetchContracts();
    setShowForm(false);
  };

  const getClientNameById = (clientId: string | number): string => {
    const client = clients.find((c) => Number(c.Id) === Number(clientId));
    return client ? client.Denumire : "Unknown Client";
  };

  const getOrganisationNameById = (organisationId: string | number): string => {
    const organisation = organisations.find((o) => o.Id === Number(organisationId));
    return organisation ? organisation.Denumire : "Unknown Organisation";
  };

  const filteredContracts = contracts.filter((contract) => {
    const clientName = getClientNameById(contract.ClientId).toLowerCase();
    const organisationName = getOrganisationNameById(contract.OrganizationId).toLowerCase();
    const description = contract.Description.toLowerCase();

    return (
      clientName.includes(searchTerm.toLowerCase()) ||
      organisationName.includes(searchTerm.toLowerCase()) ||
      description.includes(searchTerm.toLowerCase())
    );
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container">
      <button className="btn btn-primary btn-sm mb-3" onClick={toggleForm}>
        {showForm ? <Translate textKey="Close Form" /> : <Translate textKey="New Contract" />}
      </button>

      {showForm && (
        <div>
          <ContractForm
            contract={selectedContract}
            onSubmitSuccess={handleFormSubmitSuccess}
          />
        </div>
      )}

      <table className="custom-table">
        <thead className="custom-thead">
          <tr>
            <th className="custom-th"><Translate textKey="Client Name" /></th>
            <th className="custom-th"><Translate textKey="Organisation Name" /></th>
            <th className="custom-th"><Translate textKey="Description" /></th>
            <th className="custom-th"><Translate textKey="Amount" /></th>
            <th className="custom-th"><Translate textKey="Invoicing Date" /></th>
            <th className="custom-th"><Translate textKey="Action" /></th>
          </tr>
        </thead>
        <tbody>
          {filteredContracts.length > 0 ? (
            filteredContracts.map((contract) => (
              <tr key={contract.Id} className="custom-td">
                <td>{getClientNameById(contract.ClientId)}</td>
                <td>{getOrganisationNameById(contract.OrganizationId)}</td>
                <td>{contract.Description}</td>
                <td>{contract.Amount.toFixed(2)}</td>
                <td>{contract.InvoicingDate}</td>
                <td>
                  <div className="d-flex gap-2">
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={() => handleEditClick(contract)}
                    >
                      <Translate textKey="Edit" />
                    </button>
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => handleDeleteClick(contract.Id)}
                    >
                      <Translate textKey="Delete" />
                    </button>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="custom-no-data"><Translate textKey="No contracts found" /></td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default ContractList;
