import React from "react";
import { useNavigate } from "react-router-dom";
import { isAuthenticated, getUserRole } from "../utils/auth";
import LanguagePicker from "./LanguagePicker";
import Translate from "./Lang";
import ExchangeRatesSidebar from "./ExchangeRateSidebar";

const Navbar: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("UserId");
    localStorage.removeItem("UserLanguage");
    localStorage.removeItem("role");
    navigate("/login");
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const isLoggedIn = isAuthenticated();
  const role = getUserRole();

  return (
    <div className="navbar-non-bootstrap">
      <ul className="navbar-menu">
        <li>
          <LanguagePicker />
        </li>
        <li>
          <a href="/dashboard"><Translate textKey="Dashboard" /></a>
        </li>
        <li>
          <a href="/clients"><Translate textKey="Clients" /></a>
        </li>
        <li>
          <a href="/organisations"><Translate textKey="Organisations" /></a>
        </li>
        <li>
          <a href="/contracts"><Translate textKey="Contracts" /></a>
        </li>
        <li>
          <a href="/invoices"><Translate textKey="Invoices" /></a>
        </li>
        <li>
          <a href="/reports"><Translate textKey="Reports" /></a>
        </li>
        <li>
          <a href="/settings"><Translate textKey="Settings" /></a>
        </li>
        {/* Show link only for "manager" or "admin" roles */}
        {isLoggedIn && (role === "manager" || role === "admin") && (
          <li>
            <a href="/user-client-management"><Translate textKey="Management" /></a>
          </li>
        )}
        {isLoggedIn && role === "admin" && (
          <li>
            <a href="/permissions"><Translate textKey="Permissions" /></a>
          </li>
        )}
        <li>
          <ExchangeRatesSidebar />
        </li>
        <li>
          {isLoggedIn ? (
            <button onClick={handleLogout} className="btn btn-danger">
              <Translate textKey="Logout" />
            </button>
          ) : (
            <button onClick={handleLogin} className="btn btn-link">
              Login
            </button>
          )}
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
