import React, { useEffect, useState } from "react";
import axios from "axios";
import SearchBar from "./SearchBar";
import Translate from "./Lang";
import ExcelFileUpload from "../components/ExcelFileUpload";
import UnpaidFilterCheckbox from "./UnpaidFilterCheckbox";

interface InvoiceData {
  Denumire: string;
  Numar: string;
  DataEmiterii: string;
  ValoareTotala: number;
  Moneda: string;
  Achitat: number;
}

const InvoicesList: React.FC = () => {
  const [clientInvoices, setClientInvoices] = useState<InvoiceData[]>([]);
  const [filteredInvoices, setFilteredInvoices] = useState<InvoiceData[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [showOnlyUnpaid, setShowOnlyUnpaid] = useState(false);

  useEffect(() => {
    const fetchClientInvoices = async () => {
      try {
        const response = await axios.get("/api/client-invoices");

        if (response.data && Array.isArray(response.data)) {
          const sortedInvoices = response.data.sort(
            (a: InvoiceData, b: InvoiceData) =>
              new Date(b.DataEmiterii).getTime() -
              new Date(a.DataEmiterii).getTime()
          );
          setClientInvoices(sortedInvoices);
          setFilteredInvoices(sortedInvoices); // Initialize with sorted data
        } else {
          setError("Unexpected data format received from server");
        }
      } catch (error) {
        setError("Failed to fetch data");
      } finally {
        setLoading(false);
      }
    };

    fetchClientInvoices();
  }, []);

  const handleSearch = (searchTerm: string) => {
    filterInvoices(searchTerm, showOnlyUnpaid);
  };

  const handleFilterChange = (showOnlyUnpaid: boolean) => {
    setShowOnlyUnpaid(showOnlyUnpaid);
    filterInvoices("", showOnlyUnpaid);
  };

  const filterInvoices = (searchTerm: string, unpaidOnly: boolean) => {
    const filtered = clientInvoices.filter((invoice) => {
      const matchesSearch =
        invoice.Denumire.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.Numar.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.DataEmiterii.toLowerCase().includes(searchTerm.toLowerCase()) ||
        invoice.ValoareTotala.toString().includes(searchTerm) ||
        invoice.Moneda.toLowerCase().includes(searchTerm.toLowerCase());

      const matchesUnpaidFilter = !unpaidOnly || invoice.Achitat === 0;

      return matchesSearch && matchesUnpaidFilter;
    });
    setFilteredInvoices(filtered);
  };

  const toggleAchitatStatus = async (invoice: InvoiceData) => {
    try {
      const newStatus = invoice.Achitat === 1 ? 0 : 1;

      await axios.patch(`/api/client-invoices/${invoice.Numar}/toggle-status`, {
        newStatus,
      });

      const updatedInvoices = clientInvoices.map((inv) =>
        inv.Numar === invoice.Numar ? { ...inv, Achitat: newStatus } : inv
      );
      setClientInvoices(updatedInvoices);
      setFilteredInvoices(updatedInvoices);
    } catch (error) {
      setError("Failed to update invoice status");
    }
  };

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB");
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="container">
      <h1 className="my-4"><Translate textKey="Invoices List" /></h1>

      <SearchBar placeholder="Search invoices..." onSearch={handleSearch} />
      <UnpaidFilterCheckbox showOnlyUnpaid={showOnlyUnpaid} onFilterChange={handleFilterChange} />
      <ExcelFileUpload />

      <table className="custom-table">
        <thead className="custom-thead">
          <tr>
            <th className="custom-th"><Translate textKey="Client Name" /></th>
            <th className="custom-th"><Translate textKey="Invoice Number" /></th>
            <th className="custom-th"><Translate textKey="Issue Date" /></th>
            <th className="custom-th"><Translate textKey="Total Value" /></th>
            <th className="custom-th"><Translate textKey="Currency" /></th> 
            <th className="custom-th"><Translate textKey="Change status" /></th>
          </tr>
        </thead>
        <tbody>
          {filteredInvoices.length > 0 ? (
            filteredInvoices.map((invoice, idx) => (
              <tr
                key={idx}
                className={invoice.Achitat === 1 ? "custom-invoice-paid" : "custom-invoice-unpaid"}
              >
                <td className="custom-td">{invoice.Denumire}</td>
                <td className="custom-td">{invoice.Numar}</td>
                <td className="custom-td">{formatDate(invoice.DataEmiterii)}</td>
                <td className="custom-td">{invoice.ValoareTotala.toFixed(2)}</td>
                <td className="custom-td">{invoice.Moneda}</td>
                <td className="custom-td">
                <button
                  className={`status-button ${invoice.Achitat === 0 ? 'paid' : 'unpaid'}`}
                  onClick={() => toggleAchitatStatus(invoice)}
                >
                  {invoice.Achitat === 0 ? <Translate textKey="Mark as Paid" /> : <Translate textKey="Mark as Unpaid" />}
                </button>

                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6} className="custom-no-data">No invoices found</td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesList;
