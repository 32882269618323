import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Translate from '../components/Lang';
import useLabel from '../hooks/Label';

const ResetPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>(); // Get the reset token from the URL
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  // Translations
  const emailLabel = useLabel('Email');
  const passwordLabel = useLabel('Password');
  const confirmPasswordLabel = useLabel('Confirm Password');
  const resetPasswordTranslation = useLabel('Reset Password');
  const passwordsDoNotMatchTranslation = useLabel('Passwords do not match.');
  const resetLinkSentTranslation = useLabel('If an account with that email exists, a password reset link has been sent.');

  const handleRequestReset = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    try {
      console.log('Requesting password reset for:', email); // Log the email
      const response = await axios.post('/api/password-reset', { email });
      console.log('Password reset request response:', response); // Log the response
      setMessage(resetLinkSentTranslation);
    } catch (err: any) {
      console.error('Error requesting password reset:', err.response?.data || err.message); // Log the error response
      setError(err.response?.data?.message || 'An error occurred. Please try again.');
    }
  };
  
  
  const handleResetPassword = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null);
  
    if (password !== confirmPassword) {
      setError(passwordsDoNotMatchTranslation);
      return;
    }
  
    try {
      console.log('Sending new password to:', `/api/password-reset/${token}`);
      const response = await axios.post(`/api/password-reset/${token}`, { newPassword: password });
      console.log('Password reset response:', response); // Log the response from the server
      setMessage('Your password has been reset successfully.');
      // Redirect to login page after a short delay
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (err: any) {
      console.error('Error resetting password:', err); // Log the error
      setError('An error occurred. Please try again.');
    }
  };
  


  return (
    <div className="reset-password-container">

  {message && <p className="success-message">{message}</p>}
  {error && <p className="error-message">{error}</p>}
  
  {token ? (
    // Reset Password Form
    <form onSubmit={handleResetPassword}>
      <div className="input-group">
        <label>{passwordLabel}</label>
        <input
          className="search-input"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
      </div>
      <div className="input-group">
        <label>{confirmPasswordLabel}</label>
        <input
          className="search-input"
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="login-button">
        {resetPasswordTranslation}
      </button>
    </form>
  ) : (
    // Request Reset Link Form
    <form onSubmit={handleRequestReset}>
      <div className="input-group">
        
        <input
          className="search-input"
          type="email"
          value={email}
          placeholder='Email'
          onChange={(e) => setEmail(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="login-button">
        Reset password
      </button>
    </form>
  )}
</div>

  );
};

export default ResetPassword;
