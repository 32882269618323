import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import ReportComponent from '../components/ReportComponent';
import MonthlyInvoicesReport from '../components/MonthlyInvoicesReport';
import ClientReportComponent from '../components/ClientReportComponent';
import '../App.css';

const ReportPage: React.FC = () => {
    const [selectedReport, setSelectedReport] = useState<string>('Daily');

    const renderSelectedReport = () => {
        switch (selectedReport) {
            case 'Daily':
                return <ReportComponent />;
            case 'Monthly':
                return <MonthlyInvoicesReport />;
            case 'Client':
                return <ClientReportComponent />;
            default:
                return <ReportComponent />;
        }
    };

    return (
        <div>
            <Navbar />
            <main className="main-content">
                <h1>Reports</h1>
                <div className="report-selector">
                    <label htmlFor="reportType">Choose Report:</label>
                    <select
                        id="reportType"
                        value={selectedReport}
                        onChange={(e) => setSelectedReport(e.target.value)}
                        className="dropdown-reports"
                    >
                        <option value="Daily">Daily Invoice Reports</option>
                        <option value="Monthly">Monthly Invoice Reports</option>
                        <option value="Client">Client Invoice Reports</option>
                    </select>
                </div>
                
                    {renderSelectedReport()}
                
            </main>
        </div>
    );
};

export default ReportPage;
