import React, { useEffect, useState } from 'react';
import '../App.css'; // Import CSS for styles

interface User {
  Id: number;
  FirstName: string;
  LastName: string;
}

interface Client {
  Id: number;
  CompanyName: string;
}

interface Assignment {
  UserId: number;
  FirstName: string;
  LastName: string;
  ClientId: number;
  CompanyName: string;
  AssignedAt: string;
}

const UserClientAssign: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [clients, setClients] = useState<Client[]>([]);
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [expandedRows, setExpandedRows] = useState<number[]>([]);
  const [selectedUserId, setSelectedUserId] = useState<number | ''>(''); 
  const [selectedClientId, setSelectedClientId] = useState<number | ''>('');
  const [message, setMessage] = useState<string | null>(null);

  const fetchAssignments = async () => {
    try {
      const response = await fetch('/api/user-client-assignments');
      if (response.ok) {
        const data = await response.json();
        setAssignments(data);
      } else {
        console.error('Failed to fetch assignments:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching assignments:', error);
    }
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch('/api/users/filtered');
        if (response.ok) {
          const data = await response.json();
          setUsers(data);
        } else {
          console.error('Failed to fetch users:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    const fetchClients = async () => {
      try {
        const response = await fetch('/api/clients/names');
        if (response.ok) {
          const data = await response.json();
          setClients(data);
        } else {
          console.error('Failed to fetch clients:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching clients:', error);
      }
    };

    fetchUsers();
    fetchClients();
    fetchAssignments();
  }, []);

  const toggleRowExpansion = (userId: number) => {
    setExpandedRows((prevExpandedRows) =>
      prevExpandedRows.includes(userId)
        ? prevExpandedRows.filter((id) => id !== userId)
        : [...prevExpandedRows, userId]
    );
  };

  const handleAssign = async () => {
    if (selectedUserId && selectedClientId) {
      try {
        const response = await fetch('/api/assign-user-to-client', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ userId: selectedUserId, clientId: selectedClientId }),
        });

        if (response.ok) {
          setMessage('Employee successfully assigned to client!');
          await fetchAssignments(); // Refresh the assignments table after assignment
        } else if (response.status === 409) {
          setMessage('Employee is already assigned to this client.');
        } else {
          setMessage('Failed to assign user to client.');
        }
      } catch (error) {
        console.error('Error assigning user to client:', error);
        setMessage('An error occurred while assigning the user.');
      }
    } else {
      setMessage('Please select both a user and a client.');
    }
  };

  return (
    <div className="container">


      <div className="mb-3">
        <label htmlFor="user-select">Select a User:</label>
        <select
          id="user-select"
          value={selectedUserId}
          onChange={(e) => setSelectedUserId(Number(e.target.value))}
          className="form-select mb-3"
        >
          <option value="" disabled>
            Choose a user
          </option>
          {users.map((user) => (
            <option key={user.Id} value={user.Id}>
              {user.FirstName} {user.LastName}
            </option>
          ))}
        </select>

        <label htmlFor="client-select">Select a Company:</label>
        <select
          id="client-select"
          value={selectedClientId}
          onChange={(e) => setSelectedClientId(Number(e.target.value))}
          className="form-select mb-3"
        >
          <option value="" disabled>
            Choose a company
          </option>
          {clients.map((client) => (
            <option key={client.Id} value={client.Id}>
              {client.CompanyName}
            </option>
          ))}
        </select>

        <button onClick={handleAssign} className="btn btn-primary mb-3">
          Assign
        </button>
      </div>

      {message && <p className="alert alert-info">{message}</p>}

      <h3>Assigned Clients per User</h3>
      <table className="table custom-table">
  <thead className="custom-thead">
    <tr>
      <th className="custom-th" style={{ width: '30%' }}>User</th>
      <th className="custom-th" style={{ width: '70%' }}>Assigned Clients</th>
    </tr>
  </thead>
  <tbody>
    {users.map((user) => (
      <React.Fragment key={user.Id}>
        <tr>
          <td className="custom-td" style={{ width: '30%', textAlign: 'center' }}>
            <button
              onClick={() => toggleRowExpansion(user.Id)}
              className="btn btn-link"
              aria-expanded={expandedRows.includes(user.Id)}
            >
              {expandedRows.includes(user.Id) ? '▼' : '►'} {user.FirstName} {user.LastName}
            </button>
          </td>
          <td className="custom-td" style={{ width: '70%', textAlign: 'center', position: 'relative' }}>
            {expandedRows.includes(user.Id) && (
              <table style={{ margin: '0 auto', width: '100%' }}>
                <tbody>
                  {assignments
                    .filter((assignment) => assignment.UserId === user.Id)
                    .map((assignment) => (
                      <tr key={`${assignment.UserId}-${assignment.ClientId}`}>
                        <td
                          style={{
                            maxWidth: '200px',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            textAlign: 'center',
                            borderBottom: '1px solid #ddd' // Divider line
                          }}
                          title={`Assigned at: ${new Date(assignment.AssignedAt).toLocaleDateString()}`}
                        >
                          {assignment.CompanyName}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            )}
          </td>
        </tr>
      </React.Fragment>
    ))}
  </tbody>
</table>

    </div>
  );
};

export default UserClientAssign;
