import React from "react";
import Navbar from "../components/Navbar"; // Ensure this path is correct based on your project structure
import ClientList from "../components/ClientsList";

const ClientsPage: React.FC = () => {
  

  return (
    <div>
      <Navbar />
      <div className="main-content">
        <ClientList />
      </div>
    </div>
  );
};

export default ClientsPage;
