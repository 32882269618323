import React from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ClientsPage from "./page/ClientsPage";
import SettingsPage from "./page/SettingsPage";
import ContractsPage from "./page/ContractsPage";
import OrganisationsPage from "./page/OrganisationsPage";
import DashboardPage from "./page/DashboardPage";
import InvoicesPage from "./page/InvoicesPage";
import LoginPage from "./page/LoginPage";
import RegisterPage from "./page/RegisterPage";
import PermissionsPage from "./page/PermissionsPage";
import UnverifiedPage from "./page/UnverifiedPage";
import VerifyPage from "./page/VerifyPage";
import { isAuthenticated, getUserRole } from "./utils/auth";
import AdminRoute from "./utils/AdminRoute";
import VerificationSuccessPage from "./page/VerificationSuccessPage";
import RegistrationSuccessPage from "./page/RegistrationSuccessPage";
import { LanguageProvider } from "./components/LangContext";
import ResetPasswordPage from "./page/ResetPasswordPage";
import ChangePasswordPage from "./page/ChangePasswordPage";
import ExcelFileUploadPage from "./page/ExcelFileUploadPage";
import ReportsPage from "./page/ReportsPage";
import ManagementPage from "./page/ManagementPage";


// New component for protecting private routes
const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (getUserRole() === "user") {
    return <Navigate to="/unverified" />;
  }

  return children;
};

// Component for protecting user-specific routes
const UserRoute = ({ children }: { children: JSX.Element }) => {
  if (!isAuthenticated() || getUserRole() !== "user") {
    return <Navigate to="/" />;
  }
  return children;
};

ReactDOM.render(
  <React.StrictMode>
    <LanguageProvider>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} /> 
          <Route path="/reset-password/:token" element={<ResetPasswordPage />} /> 
          <Route path="/change-password-page/:token" element={<ChangePasswordPage />} />
          <Route path="/upload-excel" element={<ExcelFileUploadPage />} />
          <Route path="/registration-success" element={<RegistrationSuccessPage />} />
          
          <Route path="/dashboard" element={<PrivateRoute><DashboardPage /></PrivateRoute>} />
          <Route path="/contracts" element={<PrivateRoute><ContractsPage /></PrivateRoute>} />
          <Route path="/invoices" element={<PrivateRoute><InvoicesPage /></PrivateRoute>} />
          <Route path="/organisations" element={<PrivateRoute><OrganisationsPage /></PrivateRoute>} />
          <Route path="/clients" element={<PrivateRoute><ClientsPage /></PrivateRoute>} />
          <Route path="/settings" element={<PrivateRoute><SettingsPage /></PrivateRoute>} />
          
          <Route path="/permissions" element={<AdminRoute><PermissionsPage /></AdminRoute>} />
          <Route path="/unverified" element={<UserRoute><UnverifiedPage /></UserRoute>} />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/verification-success" element={<VerificationSuccessPage />} />
          <Route path="/reports" element={<ReportsPage />} />
          <Route path="/user-client-management" element={<ManagementPage />} />



                

          

          
          <Route
            path="/"
            element={
              isAuthenticated() ? (
                <Navigate to="/dashboard" />
              ) : (
                <Navigate to="/login" />
              )
            }
          />
        </Routes>
      </Router>
    </LanguageProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
