import React, { useEffect, useState } from 'react';
import { Dropdown, DropdownButton, Spinner, Alert } from 'react-bootstrap';
import { useLanguage } from './LangContext';
import axios from 'axios'; // Import the default axios instance

const LanguagePicker: React.FC = () => {
  const { language, setLanguage } = useLanguage(); // Get the current language and updater
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const userId = localStorage.getItem('UserId'); // Get the UserId from localStorage

  // When the component mounts, check localStorage and set the language accordingly
  useEffect(() => {
    const storedLanguage = localStorage.getItem('UserLanguage') as 'en' | 'ro' | null;
    if (storedLanguage && (storedLanguage === 'en' || storedLanguage === 'ro')) {
      setLanguage(storedLanguage); // Set the language from localStorage
    }
  }, [setLanguage]);

  const handleLanguageChange = async (lang: 'en' | 'ro') => {
    setError(null); // Reset any existing errors
    setLoading(true); // Start loading

    try {
      // Update language in context and localStorage
      setLanguage(lang);
      localStorage.setItem('UserLanguage', lang);

      // Make API request to update language in the backend if userId is available
      if (userId) {
        await axios.patch(`/api/users/${userId}/language`, { language: lang });
      } else {
        console.log('User is not logged in. Skipping database update.');
      }
    } catch (err: any) {
      console.error('Error updating language:', err);
      setError('Failed to update language. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <div>
      {error && <Alert variant="danger">{error}</Alert>}
      <DropdownButton
        id="dropdown-basic-button"
        title={language === 'en' ? 'English' : 'Română'}
        onSelect={(eventKey) =>
          eventKey && handleLanguageChange(eventKey as 'en' | 'ro')
        }
        variant="secondary"
        disabled={loading}
      >
        <Dropdown.Item eventKey="en" className="text-dark">
          English
        </Dropdown.Item>
        <Dropdown.Item eventKey="ro" className="text-dark">
          Română
        </Dropdown.Item>
      </DropdownButton>
      {loading && <Spinner animation="border" size="sm" className="ml-2" />}
    </div>
  );
};

export default LanguagePicker;
