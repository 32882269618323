// src/components/FileUpload.tsx
import React, { useState } from 'react';
import axios from 'axios';
import '../App.css'; // Import the CSS file

const FileUpload: React.FC = () => {
  const [file, setFile] = useState<File | null>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setFile(event.target.files[0]);
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    const formData = new FormData();
    formData.append('file', file);

    try {
      const response = await axios.post('/api/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(response.data.message);
    } catch (error) {
      console.error('Error uploading file:', error);
      alert('Error uploading file');
    }
  };

  return (
    <div className="file-upload-container">
      <input type="file" className="file-input" onChange={handleFileChange} />
      <button
        onClick={handleUpload}
        className="btn btn-success"
        disabled={!file}
      >
        Upload
      </button>
      
    </div>
  );
};

export default FileUpload;
