import React from 'react';
import { useNavigate } from 'react-router-dom';


const RegistrationSuccessPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login'); // Redirect to login page
  };

  return (
    <div>

      <div className="container text-center mt-5">
        <h1>Registration Successful</h1>
        <p>Please check your email for a verification link to activate your account. Once you verify your email, you will need to wait for a supervisor to approve your access.</p>
        <button onClick={handleLoginRedirect} className="btn btn-primary mt-3">
          Go to Login Page
        </button>
      </div>
    </div>
  );
};

export default RegistrationSuccessPage;
