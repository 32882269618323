import React, { useState } from "react";
import Translate from "./Lang";
import useLabel from "../hooks/Label";


interface SearchBarProps {
  placeholder?: string;
  onSearch: (searchTerm: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
    onSearch(e.target.value);
  };

  const placeholderText = useLabel("Search");

  return (
    <div className="search-bar">
      <h5>
        <Translate textKey="Search" />:
      </h5>
      <input
        type="text"
        value={searchTerm}
        onChange={handleChange}
        className="search-input"
        placeholder= {placeholderText}
      />
    </div>
  );
};

export default SearchBar;
