import React, { useState, useEffect } from "react";
import axios from "axios";
import { Modal, Button } from "react-bootstrap";
import Translate from "./Lang";

interface Organisation {
  Id: number;
  Denumire: string;
  NrRegCom: string;
  Cui: string;
  IBAN1: string;
  IBAN2: string;
  IBAN3: string;
  IBAN4: string;
  BancaIBAN1: string;
  BancaIBAN2: string;
  BancaIBAN3: string;
  BancaIBAN4: string;
  Email: string;
  Stamp: string;
  Adresa_Tara: string;
  Adresa_Judet: string;
  Adresa_Oras: string;
  Adresa_Strada_Nr: string;
  AdaugatDe: string;
  TelFix: string;
  Fax: string;
  TelMobil: string;
  CapitalSocial: string;
  SerieFactura: string;
  NumarInceputFactura: number;
  SerieFacturaProforma: string;
  NumarInceputFacturaProforma: number;
  NumarInceputAviz: number;
  SerieAviz: string;
  Web: string;
  ProcentTVA: string;
  TVALaIncasare: boolean;
  SerieChitanta: string;
  NumarInceputChitanta: number;
  Adresa_CodPostal: string;
  Adresa_SubdiviziuneISO: string;
  TaxSchemeId: number;
  PersoanaContact: string;
  UlrApiAnaf: string;
  StandardAnaf: string;
  AccessTokenAnaf: string;
  UlrApiAnafErori: string;
  UlrApiAnafMesaje: string;
}

const OrganisationsList: React.FC = () => {
  const [organisations, setOrganisations] = useState<Organisation[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [selectedOrganisation, setSelectedOrganisation] = useState<Organisation | null>(null); // To store selected org info for the modal
  const [showModal, setShowModal] = useState(false); // Modal visibility state

  // Fetch organisations from the database
  const fetchOrganisations = async () => {
    setLoading(true);
    try {
      const response = await axios.get("/api/organisations"); // Update with your correct API endpoint
      setOrganisations(response.data);
    } catch (error: any) {
      setError("Failed to fetch organisations");
      console.error("Error fetching organisations:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchOrganisations();
  }, []);

  const handleShowInfo = (organisation: Organisation) => {
    setSelectedOrganisation(organisation);
    setShowModal(true); // Show the modal when info button is clicked
  };

  const handleCloseModal = () => {
    setShowModal(false); // Hide the modal when closed
    setSelectedOrganisation(null); // Clear selected organisation
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container">
      <h1 className="my-4"><Translate textKey="Organisations" /></h1>
      {organisations.length > 0 ? (
        <table className="custom-table">
        <thead className="custom-thead">
          <tr>
            <th className="custom-th">ID</th>
            <th className="custom-th"><Translate textKey="Company Name" /></th>
            <th className="custom-th"><Translate textKey="Action" /></th>
          </tr>
        </thead>
        <tbody>
          {organisations.map((organisation) => (
            <tr key={organisation.Id}>
              <td className="custom-td">{organisation.Id}</td>
              <td className="custom-td">{organisation.Denumire}</td>
              <td className="custom-td">
                <Button variant="primary" onClick={() => handleShowInfo(organisation)}>
                  <Translate textKey="Show Info" />
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      
      ) : (
        <p><Translate textKey="No organisations found" />.</p>
      )}

      {/* Modal for showing organisation details */}
      {selectedOrganisation && (
        <Modal show={showModal} onHide={handleCloseModal} size="lg">
          <Modal.Header closeButton>
            <Modal.Title><Translate textKey="Organisation Details" /></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p><strong>Denumire:</strong> {selectedOrganisation.Denumire}</p>
            <p><strong>NrRegCom:</strong> {selectedOrganisation.NrRegCom}</p>
            <p><strong>Cui:</strong> {selectedOrganisation.Cui}</p>
            <p><strong>IBAN1:</strong> {selectedOrganisation.IBAN1}</p>
            <p><strong>IBAN2:</strong> {selectedOrganisation.IBAN2}</p>
            <p><strong>IBAN3:</strong> {selectedOrganisation.IBAN3}</p>
            <p><strong>IBAN4:</strong> {selectedOrganisation.IBAN4}</p>
            <p><strong>BancaIBAN1:</strong> {selectedOrganisation.BancaIBAN1}</p>
            <p><strong>BancaIBAN2:</strong> {selectedOrganisation.BancaIBAN2}</p>
            <p><strong>BancaIBAN3:</strong> {selectedOrganisation.BancaIBAN3}</p>
            <p><strong>BancaIBAN4:</strong> {selectedOrganisation.BancaIBAN4}</p>
            <p><strong>Email:</strong> {selectedOrganisation.Email}</p>
            <p><strong>Stamp:</strong> {new Date(selectedOrganisation.Stamp).toLocaleDateString()}</p>
            <p><strong>Adresa Tara:</strong> {selectedOrganisation.Adresa_Tara}</p>
            <p><strong>Adresa Judet:</strong> {selectedOrganisation.Adresa_Judet}</p>
            <p><strong>Adresa Oras:</strong> {selectedOrganisation.Adresa_Oras}</p>
            <p><strong>Adresa Strada Nr:</strong> {selectedOrganisation.Adresa_Strada_Nr}</p>
            <p><strong>Adaugat De:</strong> {selectedOrganisation.AdaugatDe}</p>
            <p><strong>Tel Fix:</strong> {selectedOrganisation.TelFix}</p>
            <p><strong>Fax:</strong> {selectedOrganisation.Fax}</p>
            <p><strong>Tel Mobil:</strong> {selectedOrganisation.TelMobil}</p>
            <p><strong>Capital Social:</strong> {selectedOrganisation.CapitalSocial}</p>
            <p><strong>Serie Factura:</strong> {selectedOrganisation.SerieFactura}</p>
            <p><strong>Numar Inceput Factura:</strong> {selectedOrganisation.NumarInceputFactura}</p>
            <p><strong>Serie Factura Proforma:</strong> {selectedOrganisation.SerieFacturaProforma}</p>
            <p><strong>Numar Inceput Factura Proforma:</strong> {selectedOrganisation.NumarInceputFacturaProforma}</p>
            <p><strong>Numar Inceput Aviz:</strong> {selectedOrganisation.NumarInceputAviz}</p>
            <p><strong>Serie Aviz:</strong> {selectedOrganisation.SerieAviz}</p>
            <p><strong>Web:</strong> {selectedOrganisation.Web}</p>
            <p><strong>Procent TVA:</strong> {selectedOrganisation.ProcentTVA}</p>
            <p><strong>TVA La Incasare:</strong> {selectedOrganisation.TVALaIncasare ? "Yes" : "No"}</p>
            <p><strong>Serie Chitanta:</strong> {selectedOrganisation.SerieChitanta}</p>
            <p><strong>Numar Inceput Chitanta:</strong> {selectedOrganisation.NumarInceputChitanta}</p>
            <p><strong>Adresa Cod Postal:</strong> {selectedOrganisation.Adresa_CodPostal}</p>
            <p><strong>Adresa Subdiviziune ISO:</strong> {selectedOrganisation.Adresa_SubdiviziuneISO}</p>
            <p><strong>Tax Scheme ID:</strong> {selectedOrganisation.TaxSchemeId}</p>
            <p><strong>Persoana Contact:</strong> {selectedOrganisation.PersoanaContact}</p>
            <p><strong>Ulr Api Anaf:</strong> {selectedOrganisation.UlrApiAnaf}</p>
            <p><strong>Standard Anaf:</strong> {selectedOrganisation.StandardAnaf}</p>
            <p><strong>Access Token Anaf:</strong> <span className="text-wrap">{selectedOrganisation.AccessTokenAnaf}</span></p>
            <p><strong>Ulr Api Anaf Erori:</strong> {selectedOrganisation.UlrApiAnafErori}</p>
            <p><strong>Ulr Api Anaf Mesaje:</strong> {selectedOrganisation.UlrApiAnafMesaje}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
            <Translate textKey="Close" />
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default OrganisationsList;
