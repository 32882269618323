import React, { useState } from "react";
import Navbar from "../components/Navbar"; // Ensure this path is correct based on your project structure
import ContractList from "../components/ContractsList";
import SearchBar from "../components/SearchBar"; // Import the SearchBar component
import Translate from "../components/Lang";

const ContractsPage: React.FC = () => {
  
  const [searchTerm, setSearchTerm] = useState(""); // State to store the search term
  // Handle the search term change
  const handleSearch = (term: string) => {
    setSearchTerm(term);
  };

  return (
    <div>
      <Navbar />

      <div className="main-content">
        <div className="container">
        <h1 className="my-4"><Translate textKey="Contracts" /></h1>
          {/* SearchBar here in the parent component */}
          <SearchBar placeholder="Search contracts..." onSearch={handleSearch} />
        </div>

        {/* Pass the searchTerm to ContractList */}
        <ContractList searchTerm={searchTerm} />
      </div>
    </div>
  );
};

export default ContractsPage;
