import React, { useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  BarChart,
  AreaChart,
  Line,
  Bar,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import '../App.css';

const MonthlyInvoicesReport: React.FC = () => {
  const [startMonth, setStartMonth] = useState('');
  const [endMonth, setEndMonth] = useState('');
  const [currency, setCurrency] = useState('RON'); // Add state for selected currency
  const [graphData, setGraphData] = useState<any[]>([]);
  const [chartType, setChartType] = useState<string>('line');
  const [error, setError] = useState<string | null>(null);

  const handleFetchReport = async () => {
    setError(null);
    const startDate = `${startMonth}-01`;
    const endDate = `${endMonth}-01`;

    try {
      const response = await axios.get('/api/reports/monthly', {
        params: { StartDate: startDate, EndDate: endDate, currency },
      });

      const data = response.data;

      setGraphData(data.map((entry: any) => ({
        month: entry.Month,
        value: parseFloat(entry.Total.toFixed(2)), // Limit to 2 decimal places
      })));
    } catch (err) {
      setError('Failed to fetch report');
      console.error(err);
    }
  };

  const formatMonth = (monthString: string) => {
    const date = new Date(monthString + '-01');
    return date.toLocaleDateString('en-GB', { month: 'short', year: 'numeric' });
  };

  const renderChart = (data: any[], color: string) => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tickFormatter={formatMonth} />
            <YAxis />
            <Tooltip labelFormatter={formatMonth} formatter={(value: number) => value.toFixed(2)} />
            <Legend />
            <Bar dataKey="value" fill={color} />
          </BarChart>
        );
      case 'area':
        return (
          <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tickFormatter={formatMonth} />
            <YAxis />
            <Tooltip labelFormatter={formatMonth} formatter={(value: number) => value.toFixed(2)} />
            <Legend />
            <Area type="monotone" dataKey="value" stroke={color} fill={color} />
          </AreaChart>
        );
      case 'line':
      default:
        return (
          <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="month" tickFormatter={formatMonth} />
            <YAxis />
            <Tooltip labelFormatter={formatMonth} formatter={(value: number) => value.toFixed(2)} />
            <Legend />
            <Line type="monotone" dataKey="value" stroke={color} activeDot={{ r: 8 }} />
          </LineChart>
        );
    }
  };

  return (
    <div className="report-component">
      <div className="date-inputs">
        <label>
          Start Month:
          <input type="month" value={startMonth} onChange={(e) => setStartMonth(e.target.value)} />
        </label>
        <label>
          End Month:
          <input type="month" value={endMonth} onChange={(e) => setEndMonth(e.target.value)} />
        </label>
        <button onClick={handleFetchReport} className="generate-report-button">Generate Report</button>
      </div>

      <div className="chart-settings">
        <label>
          Chart Type:
          <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
        </label>
        <label>
          Currency:
          <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="RON">RON</option>
            <option value="EUR">EUR</option>
          </select>
        </label>
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="chart-container">
        <h3>{currency} Invoiced Amount Per Month</h3>
        <ResponsiveContainer width="100%" height={400}>
          {renderChart(graphData, currency === 'RON' ? '#8884d8' : '#82ca9d')}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default MonthlyInvoicesReport;
