import React from 'react';
import { useNavigate } from 'react-router-dom';


const VerificationSuccessPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLoginRedirect = () => {
    navigate('/login'); // Redirect to login page
  };

  return (
    <div>
      <div className="container text-center mt-5">
        <h1>Verification Successful</h1>
        <p>Your email has been successfully verified.</p>
        <p>Please wait for a supervisor to verify your access before you can log in.</p>
        <button onClick={handleLoginRedirect} className="btn btn-primary mt-3">
          Go to Login Page
        </button>
      </div>
    </div>
  );
};

export default VerificationSuccessPage;
