import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, useLocation } from 'react-router-dom';
import LanguagePicker from '../components/LanguagePicker';
import Translate from '../components/Lang';
import useLabel from '../hooks/Label';

const LoginPage: React.FC = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Check if the user was redirected after registration with a success message
    if (location.state?.fromRegistration) {
      setSuccessMessage('Registration successful! Please check your email for a verification link.');
    }
  }, [location]);

  const handleLogin = async (e: React.FormEvent) => {
    e.preventDefault();
    setError(null); // Reset error state

    try {
      const response = await axios.post('/api/login', { email, password });
      if (response.status === 200) {
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('role', response.data.role);
        localStorage.setItem('UserId', response.data.Userid);
        localStorage.setItem('UserLanguage', response.data.Language);
        navigate('/dashboard'); // Redirect to the dashboard
      }
    } catch (err: any) {
      if (err.response?.status === 403) {
        setError(NonVerifiedEmailTranslation);
      } else if (err.response?.status === 401) {
        setError(InvalidLoginTranslation);
      } else {
        setError(UnexpectedErrorTranslation);
      }
    }
  };

  // Translations
  const NonVerifiedEmailTranslation = useLabel("Please, check your email and verify your account.");
  const InvalidLoginTranslation = useLabel('Invalid login credentials');
  const UnexpectedErrorTranslation = useLabel('An unexpected error occurred. Please try again later');

  const handleRegisterRedirect = () => {
    navigate('/register');
  };

  const handleForgotPasswordRedirect = () => {
    navigate('/reset-password'); // Redirect to ResetPassword page
  };

  return (
    <div className="login-container position-relative">
      {/* LanguagePicker positioned in the top left corner */}
      <div className="position-absolute top-0  m-2">
        <LanguagePicker />
      </div>

      <div className="login-box">
        <h2><Translate textKey="Login" /></h2>
        {successMessage && <p className="success-message">{successMessage}</p>}
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <label>Email</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className="input-group">
            <label><Translate textKey="Password" /></label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="login-button">
            Login
          </button>
        </form>
        <button className="register-button" onClick={handleRegisterRedirect}>
          <Translate textKey="Register" />
        </button>
        <p className="forgot-password">
  <button className="link-button" onClick={handleForgotPasswordRedirect}>
    <Translate textKey="Did you forget your password?" />
  </button>
</p>

      </div>
    </div>
  );
};

export default LoginPage;
