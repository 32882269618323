// src/utils/AdminRoute.tsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated, getUserRole } from './auth';

const AdminRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
  if (!isAuthenticated()) {
    return <Navigate to="/login" />;
  }

  if (getUserRole() !== 'admin') {
    return <Navigate to="/dashboard" />;
  }

  return children; // Render the child components if user is admin
};

export default AdminRoute;
