import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Contract {
  Id: number;
  OrganizationName: string;
  ClientName: string;
  Description: string;
  Amount: number;
  InvoicingDate: number;
}

const OverdueContracts: React.FC = () => {
  const [contracts, setContracts] = useState<Contract[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOverdueContracts = async () => {
      try {
        const response = await axios.get('/api/overdue-contracts');
        setContracts(response.data);
      } catch (error: any) {
        setError('Failed to fetch overdue contracts');
        console.error('Error fetching overdue contracts:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchOverdueContracts();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }


  return (
    <div>
      <h2>Overdue Contracts Without Invoices</h2>
      <br></br>
      {contracts.length === 0 ? (
        <p>No overdue contracts found</p>
      ) : (
        <table className="table table-bordered">
          <thead>
            <tr>

              <th>Organization Name</th>
              <th>Client Name</th>
              <th>Description</th>
              <th>Amount</th>
              <th>Invoicing Date</th>
            </tr>
          </thead>
          <tbody>
            {contracts.map((contract) => (
              <tr key={contract.Id}>

                <td>{contract.OrganizationName}</td>
                <td>{contract.ClientName}</td>
                <td>{contract.Description}</td>
                <td>{contract.Amount}</td>
                <td>{contract.InvoicingDate}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default OverdueContracts;
