import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  LineChart,
  BarChart,
  AreaChart,
  Line,
  Bar,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import '../App.css';

interface Client {
  Id: number;
  Denumire: string;
}

const ClientReportComponent: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [selectedClientId, setSelectedClientId] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currency, setCurrency] = useState('RON'); // Add state for selected currency
  const [graphData, setGraphData] = useState<any[]>([]);
  const [chartType, setChartType] = useState<string>('line');
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await axios.get('/api/clients');
        setClients(response.data);
      } catch (error) {
        console.error('Error fetching clients:', error);
        setError('Failed to load clients.');
      }
    };

    fetchClients();
  }, []);

  const handleFetchReport = async () => {
    if (!selectedClientId) {
      setError('Please select a client.');
      return;
    }

    setError(null);
    try {
      const formattedStartDate = `${startDate}-01`;
      const formattedEndDate = `${endDate}-01`;

      const response = await axios.get('/api/reports/client-monthly', {
        params: { ClientId: selectedClientId, StartDate: formattedStartDate, EndDate: formattedEndDate, currency },
      });

      setGraphData(response.data.map((entry: any) => ({
        date: entry.Month,
        value: parseFloat(entry.Total.toFixed(2)), // Limit to 2 decimal places
      })));
    } catch (err) {
      setError('Failed to fetch report');
      console.error(err);
    }
  };

  const renderChart = (data: any[], color: string) => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip formatter={(value: number) => value.toFixed(2)} />
            <Legend />
            <Bar dataKey="value" fill={color} />
          </BarChart>
        );
      case 'area':
        return (
          <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip formatter={(value: number) => value.toFixed(2)} />
            <Legend />
            <Area type="monotone" dataKey="value" stroke={color} fill={color} />
          </AreaChart>
        );
      case 'line':
      default:
        return (
          <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip formatter={(value: number) => value.toFixed(2)} />
            <Legend />
            <Line type="monotone" dataKey="value" stroke={color} activeDot={{ r: 8 }} />
          </LineChart>
        );
    }
  };

  return (
    <div className="report-component">
      <div className="date-inputs">
        <label>
          Client:
          <select value={selectedClientId} onChange={(e) => setSelectedClientId(e.target.value)}>
            <option value="">Select Client</option>
            {clients.map((client) => (
              <option key={client.Id} value={client.Id}>
                {client.Denumire}
              </option>
            ))}
          </select>
        </label>
        <label>
          Start Date:
          <input type="month" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label>
          End Date:
          <input type="month" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <button onClick={handleFetchReport} className="generate-report-button">Generate Report</button>
      </div>

      <div className="chart-settings">
        <label>
          Chart Type:
          <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
        </label>
        <label>
          Currency:
          <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="RON">RON</option>
            <option value="EUR">EUR</option>
          </select>
        </label>
      </div>

      {error && <p className="error-message">{error}</p>}

      <div className="chart-container">
        <h3>{currency} Invoiced Amount Over Time</h3>
        <ResponsiveContainer width="100%" height={400}>
          {renderChart(graphData, currency === 'RON' ? '#8884d8' : '#82ca9d')}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ClientReportComponent;
