import React from "react";
import Navbar from "../components/Navbar"; // Ensure this path is correct based on your project structure
import OrganisationsList from "../components/OrganisationsList"; // Import the OrganisationsList component

const OrganisationsPage: React.FC = () => {
  

  return (
    <div>
      <Navbar />
      <div className="main-content">
        
        <OrganisationsList />
      </div>
    </div>
  );
};

export default OrganisationsPage;
