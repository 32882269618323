import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import UserClientAssign from '../components/UserClientAssign';
import EmployeeManagement from '../components/EmployeeManagement';

const ManagementPage: React.FC = () => {
  // State to manage the selected tab
  const [selectedTab, setSelectedTab] = useState<'employee' | 'user-client'>('employee');

  // Function to handle tab selection
  const handleTabClick = (tab: 'user-client' | 'employee') => {
    setSelectedTab(tab);
  };

  return (
    <div>
      <Navbar />
      <div className="container mt-4">
        <div className="tab-buttons">
          
          <h1 
            className={`tab-title ${selectedTab === 'employee' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('employee')}
          >
            Employee Management
          </h1>
          <h1 
            className={`tab-title ${selectedTab === 'user-client' ? 'active-tab' : ''}`}
            onClick={() => handleTabClick('user-client')}
          >
            Employee-Client Assignment
          </h1>
        </div>
        <div className="tab-content">
          {selectedTab === 'user-client' && <UserClientAssign />}
          {selectedTab === 'employee' && <EmployeeManagement />}
        </div>
      </div>
    </div>
  );
};

export default ManagementPage;
