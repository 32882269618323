import React from "react";
import { useLanguage } from "./LangContext";



const translations = {
  "Synchronize Data": {
    en: "Synchronize Data",
    ro: "Sincronizare date",
  },
  welcome: {
    en: "Welcome",
    ro: "Bine ai venit",
  },
  goodbye: {
    en: "Goodbye",
    ro: "La revedere",
  },
  "Sync clients": {
    en: "Sync clients",
    ro: "Sincronizare",
  },
  'Sync Invoices': {
    en: 'Sync Invoices',
    ro: 'Sincronizează facturile',
  },
  Dashboard: {
    en: 'Dashboard',
    ro: 'Tablou de bord'
  },
  Client: {
    en: 'Client',
    ro: 'Client'
  },
  Clients: {
    en: 'Clients',
    ro: 'Clienți'
  },
  Organisations: {
    en: 'Organisations',
    ro: 'Organizații'
  },
  Contracts: {
    en: 'Contracts',
    ro: 'Contracte'
  },
  Invoices: {
    en: 'Invoices',
    ro: 'Facturi'
  },
  Reports: {
    en: 'Reports',
    ro: 'Rapoarte'
  },
  Settings: {
    en: 'Settings',
    ro: 'Setări'
  },
  Permissions: {
    en: 'Permissions',
    ro: 'Permisiuni'
  },
  Logout: {
    en: 'Logout',
    ro: 'Deconectare'
  },
  View: {
    en: 'View',
    ro: 'Inspectează'
  },
  City: {
    en: 'City',
    ro: 'Oraș'
  },
  Delete: {
    en: 'Delete',
    ro: 'Șterge'
  },
  Edit: {
    en: 'Edit',
    ro: 'Editează'
  },
  Search: {
    en: 'Search',
    ro: 'Caută'
  },
  'New Client': {
    en: 'New Client',
    ro: 'Client nou'
  },
  'Close Form': {
    en: 'Close Form',
    ro: 'Închide formularul'
  },
  "Update Client": {
    en: "Update Client",
    ro: 'Actualizează client'
  },
  'Create Client': {
    en: 'Create Client',
    ro: 'Creează client'
  },
  'Company Name': {
    en: 'Company Name',
    ro: 'Nume companie'
  },
  Action: {
    en: 'Action',
    ro: 'Acțiune'
  },
  "No organisations found": {
    en: "No organisations found",
    ro: 'Nicio organizație găsită'
  },
  'Show Info': {
    en: 'Show Info',
    ro: 'Afișează informații'
  },
  'Organisation Details': {
    en: 'Organisation Details',
    ro :"Detalii organizație"
  },
  Close: {
    en: 'Close',
    ro: 'Închide'
  },
  "Client Name": {
    en: 'Client Name',
    ro: 'Nume Client'
  },
  Description: {
    en: 'Description',
    ro: 'Descriere'
  },
  'Organisation Name': {
    en: 'Organisation Name',
    ro: 'Nume Organizație'
  },
  Amount: {
    en: 'Amount',
    ro: 'Sumă'
  },
  'Invoicing Date': {
    en: 'Invoicing Date',
    ro: 'Data Facturării'
  },
  'No contracts found': {
    en: 'No contracts found',
    ro: 'Niciun contract găsit'
  },
  "New Contract": {
    en: "New Contract",
    ro: 'Contract nou'
  },
  'Update Contract': {
    en: 'Update Contract',
    ro: 'Actualizează contract'
  },
  'Create Contract': {
    en: 'Create Contract',
    ro: 'Creează contract'
  },
  'Invoices List': {
    en: 'Invoices List',
    ro: 'Listă Facturi'
  },
  'Invoice Number': {
    en: 'Invoice Number',
    ro: 'Număr Factură'
  },
  'Issue Date': {
    en: 'Issue Date',
    ro: 'Data Emiterii'
  },
  'Total Value': {
    en: 'Total Value',
    ro: 'Valoare Totală'
  },
  Currency: {
    en: 'Currency',
    ro: 'Monedă'
  },
  'Edit Note': {
    en: 'Edit Note',
    ro: 'Editează Notă'
  },
  Reject: {
    en: 'Reject',
    ro: 'Respinge'
  },
  Approve: {
    en: 'Approve',
    ro: 'Aprobă'
  },
  Note: {
    en: 'Note',
    ro: 'Notă'
  },
  Role: {
    en: 'Role',
    ro: 'Rol'
  },
  Username: {
    en: 'Username',
    ro: 'Nume Utilizator'
  },
  'User Permissions': {
    en: 'User Permissions',
    ro: 'Permisiuni Utilizatori'
  },
  'Email Verification': {
    en: 'Email Verification',
    ro: 'Verificare Email'
  },
  Login: {
    en: 'Login',
    ro: 'Login'
  },
  Password: {
    en: 'Password',
    ro: 'Parolă'
  },
  Register: {
    en: 'Register',
    ro: 'Înregistrare'
  },
  "Did you forget your password?": {
    en: '"Did you forget your password?"',
    ro: 'Ai uitat parola?'
  },
  "Change status": {
    en: 'Change Status',
    ro: 'Schimbă Status'
  },
  "Mark as Paid":{
    en: "Mark as Paid",
    ro:"Marcheaza ca platit"
  },
  "Mark as Unpaid": {
    en: "Mark as Unpaid",
    ro:"Marcheaza ca neplatit"
  },
  Management:{
    en: "Management",
    ro: "Management"
  }


  

};
interface TranslateProps {
    textKey: keyof typeof translations; // The key for the text (e.g., 'welcome', 'goodbye')
  }
const Translate: React.FC<TranslateProps> = ({ textKey }) => {
  const { language } = useLanguage(); // Get the current language from context

  // Safely fetch the translation based on the key and language
  const translation =
    translations[textKey]?.[language] || translations[textKey]?.en || textKey;

  return <span>{translation}</span>;
};

export default Translate;
