import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Button, Table, Form } from 'react-bootstrap';
import '../App.css';

interface Employee {
  id: number;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  email: string;
}

const EmployeeManager: React.FC = () => {
  const [emails, setEmails] = useState<string[]>([]);
  const [selectedEmail, setSelectedEmail] = useState('');
  const [employees, setEmployees] = useState<Employee[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingEmployeeId, setEditingEmployeeId] = useState<number | null>(null);
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
  });

  useEffect(() => {
    fetchEmails();
    fetchEmployees();
  }, []);

  const fetchEmails = async () => {
    try {
      const response = await axios.get('/api/users');
      setEmails(response.data.map((user: any) => user.Username));
    } catch (error) {
      console.error('Error fetching emails:', error);
    }
  };

  const fetchEmployees = async () => {
    try {
      const response = await axios.get('/api/users');
      setEmployees(response.data.map((user: any) => ({
        id: user.Id,
        firstName: user.FirstName,
        lastName: user.LastName,
        phoneNumber: user.PhoneNumber,
        email: user.Email,
      })));
    } catch (error) {
      console.error('Error fetching employees:', error);
    }
  };

  const handleEmailSelect = (e: React.ChangeEvent<unknown>) => {
    const target = e.target as HTMLSelectElement;
    setSelectedEmail(target.value);
    setFormData((prevData) => ({ ...prevData, email: target.value }));
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleCreateButtonClick = () => {
    setShowForm(true);
    setIsEditMode(false);
    setFormData({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    });
    setSelectedEmail('');
  };

  const handleEditButtonClick = (employee: Employee) => {
    setShowForm(true);
    setIsEditMode(true);
    setEditingEmployeeId(employee.id);
    setFormData({
      firstName: employee.firstName,
      lastName: employee.lastName,
      phoneNumber: employee.phoneNumber,
      email: employee.email,
    });
    setSelectedEmail(employee.email);
  };

  const handleCloseForm = () => {
    setShowForm(false);
    setIsEditMode(false);
    setFormData({
      firstName: '',
      lastName: '',
      phoneNumber: '',
      email: '',
    });
    setSelectedEmail('');
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const selectedEmployee = employees.find((employee) => employee.email === selectedEmail);

    if (!selectedEmployee && !isEditMode) {
      alert('Selected employee not found.');
      return;
    }

    try {
      const url = isEditMode
        ? `/api/users/${editingEmployeeId}/update-details`
        : `/api/users/${selectedEmployee?.id}/update-details`;

      await axios.patch(url, formData);
      fetchEmployees();
      setShowForm(false);
    } catch (error) {
      console.error('Error saving employee:', error);
      alert('Failed to save employee.');
    }
  };

  const filteredEmployees = employees.filter((employee) => employee.firstName);

  return (
    <div className="container">

      <Button variant="primary" onClick={handleCreateButtonClick} className="mb-3">
        Create Employee
      </Button>

      {showForm && (
        <Form onSubmit={handleSubmit} className="p-4 bg-light rounded shadow-sm mb-4">
          <Form.Group className="mb-3">
            <Form.Label>Email:</Form.Label>
            <Form.Control as="select" value={selectedEmail} onChange={handleEmailSelect} required disabled={isEditMode}>
              <option value="">Select an email</option>
              {emails
                .filter((email) => !filteredEmployees.some((emp) => emp.email === email))
                .map((email, index) => (
                  <option key={`${email}-${index}`} value={email}>
                    {email}
                  </option>
                ))}
            </Form.Control>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>First Name:</Form.Label>
            <Form.Control type="text" name="firstName" value={formData.firstName} onChange={handleChange} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Last Name:</Form.Label>
            <Form.Control type="text" name="lastName" value={formData.lastName} onChange={handleChange} />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Phone Number:</Form.Label>
            <Form.Control type="text" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} />
          </Form.Group>

          <Button variant="success" type="submit" className="me-2">
            {isEditMode ? 'Update Employee' : 'Add Employee'}
          </Button>
          <Button variant="secondary" onClick={handleCloseForm}>
            Close Form
          </Button>
        </Form>
      )}

      <Table striped bordered hover className="custom-table">
        <thead className="custom-thead">
          <tr>
            <th className="custom-th">Email</th>
            <th className="custom-th">First Name</th>
            <th className="custom-th">Last Name</th>
            <th className="custom-th">Phone Number</th>
            <th className="custom-th">Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredEmployees.length > 0 ? (
            filteredEmployees.map((employee) => (
              <tr key={employee.id} className="custom-invoice-unpaid">
                <td className="custom-td">{employee.email}</td>
                <td className="custom-td">{employee.firstName || 'N/A'}</td>
                <td className="custom-td">{employee.lastName || 'N/A'}</td>
                <td className="custom-td">{employee.phoneNumber || 'N/A'}</td>
                <td className="custom-td">
                  <Button variant="primary" size="sm" onClick={() => handleEditButtonClick(employee)} className="me-2">
                    Edit User
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td className="custom-td custom-no-data" colSpan={5}>
                No employees to display
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default EmployeeManager;
