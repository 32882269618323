import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Navbar from '../components/Navbar'; // Import Navbar component
import { useNavigate } from 'react-router-dom'; // Import useNavigate for navigation
import '../App.css'; // Import CSS for styles
import Tile from '../components/Tile'; // Import the new Tile component
import OverdueContracts from '../components/OverdueContracts';
import Translate from '../components/Lang';

const DashboardPage: React.FC = () => {
  const [dashboardData, setDashboardData] = useState({
    clientCount: 0,
    contractCount: 0,
    organisationCount: 0,
    invoicesCount: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const navigate = useNavigate(); // Use navigate hook for navigation

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('/api/dashboard'); // Adjust URL if necessary
        setDashboardData(response.data);
      } catch (err: any) {
        setError('Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  // Handlers to navigate to different pages
  const goToClients = () => navigate('/clients');
  const goToContracts = () => navigate('/contracts');
  const goToOrganisations = () => navigate('/organisations');
  const goToinvoices = () => navigate('/invoices');

  return (
    <div>
      <Navbar />

      <div className="main-content">
        <h1><Translate textKey="Dashboard" /></h1>
        <div className="dashboard-tiles">
          {/* Use the reusable Tile component */}
          <Tile title={<Translate textKey="Invoices" />} count={dashboardData.invoicesCount} onClick={goToinvoices} />
          <Tile title={<Translate textKey="Clients" />} count={dashboardData.clientCount} onClick={goToClients} />
          <Tile title={<Translate textKey="Contracts" />} count={dashboardData.contractCount} onClick={goToContracts} />
          <Tile title={<Translate textKey="Organisations" />} count={dashboardData.organisationCount} onClick={goToOrganisations} />

          
        </div>
        <br></br>
        <OverdueContracts />
      </div>
    </div>
  );
};

export default DashboardPage;
