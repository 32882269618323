import React from 'react';

interface UnpaidFilterCheckboxProps {
  showOnlyUnpaid: boolean;
  onFilterChange: (showOnlyUnpaid: boolean) => void;
}

const UnpaidFilterCheckbox: React.FC<UnpaidFilterCheckboxProps> = ({ showOnlyUnpaid, onFilterChange }) => {
  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    onFilterChange(event.target.checked);
  };

  return (
    <div className="form-check mb-3">
      <input
        type="checkbox"
        id="unpaidFilter"
        className="form-check-input"
        checked={showOnlyUnpaid}
        onChange={handleCheckboxChange}
      />
      <label htmlFor="unpaidFilter" className="form-check-label">
        Show only unpaid invoices
      </label>
    </div>
  );
};

export default UnpaidFilterCheckbox;
