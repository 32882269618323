import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Translate from './Lang';

interface ClientFormProps {
  client?: any; // The client data to prepopulate when editing
  onSubmitSuccess?: () => void; // Callback for handling successful form submission
}

const ClientForm: React.FC<ClientFormProps> = ({ client, onSubmitSuccess }) => {
  const formRef = useRef<HTMLDivElement>(null); // Create a reference to the form element

  const [formData, setFormData] = useState({
    Denumire: '',
    NrRegCom: '',
    Cui: '',
    Cont: '',
    Banca: '',
    Email: '',
    Observatii: '',
    Adresa_Tara: '',
    Adresa_Judet: '',
    Adresa_Oras: '',
    Adresa_Strada_Nr: '',
    AdaugatDe: '',
    Adresa_CodPostal: '',
    Adresa_SubdiviziuneISO: ''
  });

  const [message, setMessage] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  // Prepopulate form if client data is passed (edit mode)
  useEffect(() => {
    if (client) {
      setFormData(client); // Pre-fill the form with client data
      setIsFormVisible(true); // Ensure the form is visible when editing
    } else {
      resetForm(); // Reset form if no client data is provided (new client mode)
    }

    // Scroll to the form when it becomes visible
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the form
    }
  }, [client]);

  const resetForm = () => {
    setFormData({
      Denumire: '',
      NrRegCom: '',
      Cui: '',
      Cont: '',
      Banca: '',
      Email: '',
      Observatii: '',
      Adresa_Tara: '',
      Adresa_Judet: '',
      Adresa_Oras: '',
      Adresa_Strada_Nr: '',
      AdaugatDe: '',
      Adresa_CodPostal: '',
      Adresa_SubdiviziuneISO: ''
    });
    setMessage('');
    setIsFormVisible(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (client) {
        // Update existing client (PUT request)
        await axios.put(`/api/clients/${client.Id}`, formData);
        setMessage('Client updated successfully!');
      } else {
        // Add new client (POST request)
        await axios.post('/api/clients', formData);
        setMessage('Client added successfully!');
      }

      if (onSubmitSuccess) {
        onSubmitSuccess(); // Call the success callback to refresh the list
      }

      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  return (
    <div ref={formRef}> {/* Attach the formRef to this div */}
      {isFormVisible ? (
        <form onSubmit={handleSubmit} className="needs-validation">
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Denumire:</label>
              <input
                type="text"
                name="Denumire"
                value={formData.Denumire}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>NrRegCom:</label>
              <input
                type="text"
                name="NrRegCom"
                value={formData.NrRegCom}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Cui:</label>
              <input
                type="text"
                name="Cui"
                value={formData.Cui}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Cont:</label>
              <input
                type="text"
                name="Cont"
                value={formData.Cont}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Banca:</label>
              <input
                type="text"
                name="Banca"
                value={formData.Banca}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Email:</label>
              <input
                type="email"
                name="Email"
                value={formData.Email}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Observatii:</label>
              <textarea
                name="Observatii"
                value={formData.Observatii}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Adresa_Tara:</label>
              <input
                type="text"
                name="Adresa_Tara"
                value={formData.Adresa_Tara}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Adresa_Judet:</label>
              <input
                type="text"
                name="Adresa_Judet"
                value={formData.Adresa_Judet}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Adresa_Oras:</label>
              <input
                type="text"
                name="Adresa_Oras"
                value={formData.Adresa_Oras}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Adresa_Strada_Nr:</label>
              <input
                type="text"
                name="Adresa_Strada_Nr"
                value={formData.Adresa_Strada_Nr}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>AdaugatDe:</label>
              <input
                type="text"
                name="AdaugatDe"
                value={formData.AdaugatDe}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Adresa_CodPostal:</label>
              <input
                type="text"
                name="Adresa_CodPostal"
                value={formData.Adresa_CodPostal}
                onChange={handleChange}
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Adresa_SubdiviziuneISO:</label>
              <input
                type="text"
                name="Adresa_SubdiviziuneISO"
                value={formData.Adresa_SubdiviziuneISO}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {client ? <Translate textKey="Update Client" /> : <Translate textKey='Create Client' />}
          </button>
        </form>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default ClientForm;
