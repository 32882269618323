import React, { useState, useEffect } from 'react';
import ClientForm from './ClientForm';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import SearchBar from './SearchBar'; // Import the SearchBar component
import Translate from './Lang';

interface Client {
  Id: number;
  Denumire: string;
  NrRegCom: string;
  Cui: string;
  Cont: string;
  Banca: string;
  Email: string;
  Observatii: string;
  Stamp: string;
  Adresa_Tara: string;
  Adresa_Judet: string;
  Adresa_Oras: string;
  Adresa_Strada_Nr: string;
  AdaugatDe: string;
  Deleted: number;
  Adresa_CodPostal: string;
  Adresa_SubdiviziuneISO: string;
}

const ClientList: React.FC = () => {
  const [clients, setClients] = useState<Client[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [selectedClient, setSelectedClient] = useState<Client | null>(null);
  const [searchTerm, setSearchTerm] = useState(''); // State for search term

  const toggleForm = () => {
    setShowForm(!showForm);
    setSelectedClient(null); // Reset form when toggling
  };

  const handleEditClick = (client: Client) => {
    setSelectedClient(client); // Set the client to be edited
    setShowForm(true); // Show the form with the client data
  };

  // Function to handle deleting a client
  const handleDeleteClick = async (clientId: number) => {
    const confirmed = window.confirm("Are you sure you want to delete this client?");
    if (!confirmed) return;

    try {
      await axios.delete(`/api/clients/${clientId}`);
      fetchClients(); // Refresh the client list after deletion
    } catch (error) {
      console.error("Error deleting client:", error);
    }
  };

  // Fetch clients from the server
  const fetchClients = async () => {
    setLoading(true);
    try {
      const response = await fetch('/api/clients');
      if (!response.ok) {
        throw new Error('Failed to fetch clients');
      }
      const data = await response.json();
      setClients(data);
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchClients(); // Fetch clients on component mount
  }, []);

  // Callback to handle form submission success
  const handleFormSubmitSuccess = () => {
    fetchClients(); // Refresh the client list after submitting
    setShowForm(false); // Close the form
  };

  // Filter the clients based on search term
  const filteredClients = clients.filter((client) =>
    client.Denumire.toLowerCase().includes(searchTerm.toLowerCase())
  );

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <div className="container">
      <h1 className="my-4"><Translate textKey="Clients" /></h1>

      {/* SearchBar to filter clients */}
      <SearchBar placeholder="Search Clients..." onSearch={(term) => setSearchTerm(term)} />

      <button
        className="btn btn-primary btn-sm mb-3"
        onClick={toggleForm}
      >
        {showForm ? <Translate textKey="Close Form" /> : <Translate textKey="New Client" />}
      </button>

      {showForm && (
        <div>
          <ClientForm
            client={selectedClient}
            onSubmitSuccess={handleFormSubmitSuccess} // Pass the success callback
          />
        </div>
      )}

      <ul className="list-group">
        {filteredClients.map((client) => (
          <li key={client.Id} className="list-group-item">
            <div className="d-flex justify-content-between">
              <div>
                <h5><Translate textKey="Client" />: {client.Denumire}</h5>
                <p><Translate textKey="City" />: {client.Adresa_Oras}</p>
                <p>Email: {client.Email}</p>
              </div>
              <div>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => handleEditClick(client)}
                >
                  <Translate textKey="Edit" />
                </button>
                <button
                  className="btn btn-danger btn-sm ms-2"
                  onClick={() => handleDeleteClick(client.Id)}
                >
                  <Translate textKey="Delete" />
                </button>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ClientList;
