// src/pages/ChangePassword.tsx

import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

const ChangePassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleChangePassword = async (e: React.FormEvent) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`/api/password-reset/${token}`, {
        newPassword,
      });
      setMessage(response.data.message);
      setTimeout(() => navigate('/login'), 3000); // Redirect to login after success
    } catch (err) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div className="login-container">
  <div className="login-box">
    <h2>Change Password</h2>
    {message && <p className="success-message">{message}</p>}
    {error && <p className="error-message">{error}</p>}
    <form onSubmit={handleChangePassword}>
      <div className="input-group">
        <label>New Password</label>
        <input
          type="password"
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
          required
        />
      </div>
      <div className="input-group">
        <label>Confirm Password</label>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
        />
      </div>
      <button type="submit" className="login-button">Submit</button>
    </form>
  </div>
</div>

  );
};

export default ChangePassword;
