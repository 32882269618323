import React from 'react';
import Navbar from '../components/Navbar';
import ClientInfo from '../components/ClientInfo'; // Import the ClientInfo component
import ActivityScheduler from '../components/ActivityScheduler'; // Import the ActivityScheduler component

const ExcelFileUploadPage: React.FC = () => {
  const testClientId = 213; // Example client ID

  return (
    <div>
      <Navbar />
      <div className="main-content">
        {/* Render the ActivityScheduler component with the same client ID */}
        <ActivityScheduler clientId={testClientId} />
        
        <hr />
        <h2>Test Client Info and Activities</h2>
        
        {/* Render the ClientInfo component with a sample client ID */}
        <ClientInfo clientId={testClientId} />
        
        
      </div>
    </div>
  );
};

export default ExcelFileUploadPage;
