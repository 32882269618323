
export const translations: { [key: string]: { [lang: string]: string } } = {

    Search: {
      en: "Search",
      ro: "Caută",
    },
    'Please, check your email and verify your account.': {
        en: 'Please, check your email and verify your account.',
        ro: 'Vă rugăm să verificați emailul și să vă confirmați contul.'
    },
    'Invalid login credentials': {
        en: 'Invalid login credentials',
        ro: 'Date de autentificare invalide'
    },
    'An unexpected error occurred. Please try again later': {
        en: 'An unexpected error occurred. Please try again later',
        ro: 'A apărut o eroare neașteptată. Vă rugăm să încercați din nou mai târziu'
    },
    'Did you forget your password?': {
    en: 'Did you forget your password?',
    ro: 'Ai uitat parola?',
  },
  'Email': {
    en: 'Email',
    ro: 'Email',
  },
  'Password': {
    en: 'Password',
    ro: 'Parolă',
  },
  'Confirm Password': {
    en: 'Confirm Password',
    ro: 'Confirmă Parola',
  },
  'Reset Password': {
    en: 'Reset Password',
    ro: 'Resetează Parola',
  },
  'Request Password Reset': {
    en: 'Request Password Reset',
    ro: 'Solicită Resetarea Parolei',
  },
  'Passwords do not match.': {
    en: 'Passwords do not match.',
    ro: 'Parolele nu se potrivesc.',
  },
  'If an account with that email exists, a password reset link has been sent.': {
    en: 'If an account with that email exists, a password reset link has been sent.',
    ro: 'Dacă există un cont cu acest email, un link pentru resetarea parolei a fost trimis.',
  },
  'Your password has been reset successfully.': {
    en: 'Your password has been reset successfully.',
    ro: 'Parola a fost resetată cu succes.',
  },


    
    // Add more translation keys as needed
  };
  