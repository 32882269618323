// src/pages/ChangePasswordPage.tsx

import React from 'react';
import ChangePassword from '../components/ChangePassword';
import { useParams } from 'react-router-dom';




const ChangePasswordPage: React.FC = () => {

    const { token } = useParams<{ token: string }>();

//   // You can use the token in your form submission logic
//   // For example:
//   const handlePasswordReset = async () => {
//     // Use the token to submit the new password to the backend
//   };

  return (
    <div className="change-password-page">
      <ChangePassword />
    </div>
  );
};

export default ChangePasswordPage;
