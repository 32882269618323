import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Translate from './Lang';

interface ContractFormProps {
  contract?: any; // The contract data to prepopulate when editing
  onSubmitSuccess?: () => void; // Callback for handling successful form submission
}

interface Client {
  Id: number;
  Denumire: string;
}

interface Organisation {
  Id: number;
  Denumire: string;
}

const ContractForm: React.FC<ContractFormProps> = ({ contract, onSubmitSuccess }) => {
  const formRef = useRef<HTMLDivElement>(null); // Create a reference to the form element

  const [formData, setFormData] = useState({
    OrganizationId: '',
    ClientId: '',
    Description: '',
    Amount: '',
    InvoicingDate: '',
    billing_type: '' // New billing_type field
  });

  const [clients, setClients] = useState<Client[]>([]); // Store the list of clients
  const [organisations, setOrganisations] = useState<Organisation[]>([]); // Store the list of organisations
  const [message, setMessage] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  // Fetch clients and organisations on component mount
  useEffect(() => {
    const fetchClientsAndOrganisations = async () => {
      try {
        // Fetch clients
        const clientResponse = await axios.get('/api/clients');
        setClients(clientResponse.data); // Store fetched clients

        // Fetch organisations
        const organisationResponse = await axios.get('/api/organisations');
        setOrganisations(organisationResponse.data); // Store fetched organisations
      } catch (error) {
        console.error('Error fetching clients or organisations:', error);
      }
    };

    fetchClientsAndOrganisations();
  }, []);

  // Prepopulate form if contract data is passed (edit mode)
  useEffect(() => {
    if (contract) {
      setFormData(contract); // Pre-fill the form with contract data
      setIsFormVisible(true); // Ensure the form is visible when editing
    } else {
      resetForm(); // Reset form if no contract data is provided (new contract mode)
    }

    // Scroll to the form when it becomes visible
    if (formRef.current) {
      formRef.current.scrollIntoView({ behavior: 'smooth' }); // Scroll smoothly to the form
    }
  }, [contract]);

  const resetForm = () => {
    setFormData({
      OrganizationId: '',
      ClientId: '',
      Description: '',
      Amount: '',
      InvoicingDate: '',
      billing_type: '' // Reset billing_type as well
    });
    setMessage('');
    setIsFormVisible(true);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      if (contract) {
        // Update existing contract (PUT request)
        await axios.put(`/api/contracts/${contract.Id}`, formData);
        setMessage('Contract updated successfully!');
      } else {
        // Add new contract (POST request)
        await axios.post('/api/contracts', formData);
        setMessage('Contract added successfully!');
      }

      if (onSubmitSuccess) {
        onSubmitSuccess(); // Call the success callback to refresh the list
      }

      resetForm(); // Reset the form after successful submission
    } catch (error) {
      console.error('There was an error submitting the form!', error);
    }
  };

  return (
    <div ref={formRef}> {/* Attach the formRef to this div */}
      {isFormVisible ? (
        <form onSubmit={handleSubmit} className="needs-validation">
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Organisation:</label>
              <select
                name="OrganizationId"
                value={formData.OrganizationId}
                onChange={handleChange}
                required
                className="form-control"
              >
                <option value="">Select Organisation</option>
                {organisations.map((organisation) => (
                  <option key={organisation.Id} value={organisation.Id}>
                    {organisation.Denumire}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 mb-3">
              <label>Client:</label>
              <select
                name="ClientId"
                value={formData.ClientId}
                onChange={handleChange}
                required
                className="form-control"
              >
                <option value="">Select Client</option>
                {clients.map((client) => (
                  <option key={client.Id} value={client.Id}>
                    {client.Denumire}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-md-4 mb-3">
              <label>Description:</label>
              <input
                type="text"
                name="Description"
                value={formData.Description}
                onChange={handleChange}
                className="form-control"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="col-md-4 mb-3">
              <label>Amount:</label>
              <input
                type="number"
                name="Amount"
                value={formData.Amount}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Invoicing Date:</label>
              <input
                type="number"
                name="InvoicingDate"
                value={formData.InvoicingDate}
                onChange={handleChange}
                required
                className="form-control"
              />
            </div>
            <div className="col-md-4 mb-3">
              <label>Billing Type:</label> {/* New billing_type field */}
              <select
                name="billing_type"
                value={formData.billing_type}
                onChange={handleChange}
                required
                className="form-control"
              >
                <option value="">Select Billing Type</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
            </div>
          </div>
          <button type="submit" className="btn btn-primary">
            {contract ? <Translate textKey='Update Contract' /> : <Translate textKey='Create Contract' />}
          </button>
        </form>
      ) : (
        <p>{message}</p>
      )}
    </div>
  );
};

export default ContractForm;
