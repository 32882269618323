import React from "react";
import Navbar from "../components/Navbar";
import SyncButton from "../components/SyncButton";

const SettingsPage: React.FC = () => {
  return (
    <div style={{ display: "flex" }}>
      <Navbar />
      <div style={{ marginLeft: "200px", padding: "20px", width: "100%" }}>
        

        <SyncButton />
      </div>
    </div>
  );
};

export default SettingsPage;
