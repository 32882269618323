// useLabel.ts
import { useLanguage } from "../components/LangContext";
import { translations } from "./translations";

/**
 * Custom hook to get the translated string based on the key.
 * @param key - The translation key.
 * @returns The translated string.
 */
const useLabel = (key: string): string => {
  const { language } = useLanguage(); // Access the current language from context

  // Retrieve the translation for the given key and language
  const translatedString = translations[key]?.[language];

  // Fallback to the key itself if translation is missing
  if (!translatedString) {
    console.warn(`Missing translation for key: "${key}" in language: "${language}"`);
    return key;
  }

  return translatedString;
};

export default useLabel;
