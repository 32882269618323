// SyncButton.tsx

import React, { useState } from 'react';
import axios from 'axios';
import { Button, Spinner, Alert, Container, Row, Col } from 'react-bootstrap';
import Translate from './Lang';


const SyncButton: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<string | null>(null);
  const [error, setError] = useState<string | null>(null);

  const handleSyncClients = async () => {
    setLoading(true);
    setMessage(null);
    setError(null);
    try {
      const response = await axios.post(
        '/api/syncClients',
        {},
        {
          headers: {
            'x-api-key': 'your-secure-api-key', // Replace with your actual API key
          },
        }
      );
      setMessage(response.data.message);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An error occurred during synchronization.');
      }
    } finally {
      setLoading(false);
    }
  };

  const handleSyncInvoices = async () => {
    setLoading(true);
    setMessage(null);
    setError(null);
    try {
      const response = await axios.post(
        '/api/syncInvoices',
        {},
        {
          headers: {
            'x-api-key': 'your-secure-api-key', // Replace with your actual API key
          },
        }
      );
      setMessage(response.data.message);
    } catch (err: unknown) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError('An error occurred during synchronization.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Container className="mt-4">
      <h2>
          <Translate textKey="Synchronize Data" />
        </h2>
      <Row className="justify-content-left">
        <Col md={6}>
          {/* Sync Clients Button */}
          <Button
            variant="primary"
            size="lg"
            className="w-100 mb-3"
            onClick={handleSyncClients}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2" // Changed from 'mr-2' to 'me-2' for Bootstrap 5
                />
                Synchronizing Clients...
              </>
            ) : (
              <Translate textKey="Sync clients" />
            )}
          </Button>

          {/* Sync Invoices Button */}
          <Button
            variant="success"
            size="lg"
            className="w-100"
            onClick={handleSyncInvoices}
            disabled={loading}
          >
            {loading ? (
              <>
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                  className="me-2" // Changed from 'mr-2' to 'me-2' for Bootstrap 5
                />
                Synchronizing Invoices...
              </>
            ) : (
              <Translate textKey='Sync Invoices' />
              
            )}
          </Button>

          {/* Success Message */}
          {message && (
            <Alert variant="success" className="mt-4">
              {message}
            </Alert>
          )}

          {/* Error Message */}
          {error && (
            <Alert variant="danger" className="mt-4">
              {error}
            </Alert>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SyncButton;
