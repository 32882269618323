import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Client {
  Id: number;
  Denumire: string;
  NrRegCom: string;
  Cui: string;
  Cont: string;
  Banca: string;
  Email: string;
  Observatii: string;
  Stamp: string;
  Adresa_Tara: string;
  Adresa_Judet: string;
  Adresa_Oras: string;
  Adresa_Strada_Nr: string;
  AdaugatDe: string;
  Deleted: boolean;
  Adresa_CodPostal: string;
  Adresa_SubdiviziuneISO: string;
}

interface ClientInfoProps {
  clientId: number;
}

const ClientInfo: React.FC<ClientInfoProps> = ({ clientId }) => {
  const [clientSummary, setClientSummary] = useState<Client | null>(null);
  const [clientDetails, setClientDetails] = useState<Client | null>(null);
  const [showDetails, setShowDetails] = useState(false);

  // Fetch summarized client info
  useEffect(() => {
    const fetchClientSummary = async () => {
      try {
        const response = await axios.get(`/api/client/${clientId}/summary`);
        setClientSummary(response.data);
      } catch (error) {
        console.error("Error fetching client summary:", error);
      }
    };
    fetchClientSummary();
  }, [clientId]);

  // Fetch detailed client info
  const handleShowDetails = async () => {
    if (!clientDetails) {
      try {
        const response = await axios.get(`/api/client/${clientId}/details`);
        setClientDetails(response.data);
      } catch (error) {
        console.error("Error fetching client details:", error);
      }
    }
    setShowDetails(!showDetails);
  };

  if (!clientSummary) {
    return <p>Loading client info...</p>;
  }

  return (
    <div>
      <h2>Client Information</h2>
      <p><strong>Name:</strong> {clientSummary.Denumire}</p>
      <p><strong>Registration Number:</strong> {clientSummary.NrRegCom}</p>
      <p><strong>Tax ID:</strong> {clientSummary.Cui}</p>
      <p><strong>Email:</strong> {clientSummary.Email}</p>
      <p><strong>City:</strong> {clientSummary.Adresa_Oras}</p>
      
      <button onClick={handleShowDetails}>
        {showDetails ? "Show Less" : "Show More"}
      </button>

      {showDetails && clientDetails && (
        <div>
          <h3>Additional Details</h3>
          <p><strong>Client ID:</strong> {clientDetails.Id}</p>
          <p><strong>Name:</strong> {clientDetails.Denumire}</p>
          <p><strong>Registration Number:</strong> {clientDetails.NrRegCom}</p>
          <p><strong>Tax ID:</strong> {clientDetails.Cui}</p>
          <p><strong>Bank Account:</strong> {clientDetails.Cont}</p>
          <p><strong>Bank:</strong> {clientDetails.Banca}</p>
          <p><strong>Email:</strong> {clientDetails.Email}</p>
          <p><strong>Notes:</strong> {clientDetails.Observatii}</p>
          <p><strong>Timestamp:</strong> {clientDetails.Stamp}</p>
          <p><strong>Country:</strong> {clientDetails.Adresa_Tara}</p>
          <p><strong>State/County:</strong> {clientDetails.Adresa_Judet}</p>
          <p><strong>City:</strong> {clientDetails.Adresa_Oras}</p>
          <p><strong>Street Address:</strong> {clientDetails.Adresa_Strada_Nr}</p>
          <p><strong>Added By:</strong> {clientDetails.AdaugatDe}</p>
          <p><strong>Deleted:</strong> {clientDetails.Deleted ? 'Yes' : 'No'}</p>
          <p><strong>Postal Code:</strong> {clientDetails.Adresa_CodPostal}</p>
          <p><strong>Subdivision ISO:</strong> {clientDetails.Adresa_SubdiviziuneISO}</p>
        </div>
      )}
    </div>
  );
};

export default ClientInfo;
