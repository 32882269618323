import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Activity {
  ActivityId: number;
  ClientId: number;
  UserId: number;
  ActivityType: string;
  Details?: string;
  Link?: string;
  AttachmentPath?: string;
  DateTime: string;
  CreatedAt: string;
  Username: string; // User who created the activity
}

interface ActivitySchedulerProps {
  clientId: number;
}

const ActivityScheduler: React.FC<ActivitySchedulerProps> = ({ clientId }) => {
  const [activities, setActivities] = useState<Activity[]>([]);
  const [activityType, setActivityType] = useState('');
  const [details, setDetails] = useState('');
  const [link, setLink] = useState('');
  const [attachment, setAttachment] = useState<File | null>(null);
  const [dateTime, setDateTime] = useState('');

  // Fetch activities when the component mounts
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const response = await axios.get(`/api/client/${clientId}/activities`);
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };

    fetchActivities();
  }, [clientId]);

  // Handle form submission to add a new activity
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('clientId', clientId.toString());
    formData.append('userId', '1'); // Replace with actual user ID
    formData.append('activityType', activityType);
    formData.append('details', details);
    formData.append('link', link);
    formData.append('dateTime', dateTime);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      await axios.post('/api/client/activity', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setActivityType('');
      setDetails('');
      setLink('');
      setAttachment(null);
      setDateTime('');
      // Refresh activities
      const response = await axios.get(`/api/client/${clientId}/activities`);
      setActivities(response.data);
    } catch (error) {
      console.error('Error adding activity:', error);
    }
  };

  return (
    <div>
      <h2>Schedule an Activity</h2>
      <form onSubmit={handleFormSubmit}>
        <label>Activity Type:</label>
        <select value={activityType} onChange={(e) => setActivityType(e.target.value)} required>
          <option value="">Select an activity type</option>
          <option value="meeting">Meeting</option>
          <option value="support">Support Request</option>
          <option value="offer">Offer</option>
        </select>

        <label>Details:</label>
        <textarea value={details} onChange={(e) => setDetails(e.target.value)} />

        <label>Link:</label>
        <input type="url" value={link} onChange={(e) => setLink(e.target.value)} />

        <label>Date and Time:</label>
        <input type="datetime-local" value={dateTime} onChange={(e) => setDateTime(e.target.value)} required />

        <label>Attachment:</label>
        <input type="file" onChange={(e) => setAttachment(e.target.files ? e.target.files[0] : null)} />

        <button type="submit">Add Activity</button>
      </form>

      <h2>Activities</h2>
      <table>
        <thead>
          <tr>
            <th>Date and Time</th>
            <th>Activity Type</th>
            <th>Details</th>
            <th>Link</th>
            <th>Attachment</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {activities.map((activity) => (
            <tr key={activity.ActivityId}>
              <td>{new Date(activity.DateTime).toLocaleString()}</td>
              <td>{activity.ActivityType}</td>
              <td>{activity.Details || '-'}</td>
              <td>{activity.Link ? <a href={activity.Link} target="_blank" rel="noopener noreferrer">Open Link</a> : '-'}</td>
              <td>
                {activity.AttachmentPath ? (
                  <a href={`/clientAttachments/${activity.AttachmentPath}`} target="_blank" rel="noopener noreferrer">
                    Download
                  </a>
                ) : '-'}
              </td>
              <td>{activity.Username}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ActivityScheduler;
