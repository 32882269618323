import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Navbar from '../components/Navbar';
import axios from 'axios';
import Translate from '../components/Lang';

const VerifyPage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('Verifying...');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get('token');

    const verifyEmail = async () => {
      if (token) {
        try {
          const response = await axios.get(`/api/verify?token=${token}`);
          setMessage(response.data.message);

          // Redirect to the verification success page after successful verification
          navigate('/verification-success'); // Adjust the route as necessary
        } catch (error) {
          console.error('Error verifying email:', error);
          if (axios.isAxiosError(error)) {
            setMessage(error.response?.data?.message || 'Email verification failed.');
          } else {
            setMessage('An unexpected error occurred.');
          }
        }
      } else {
        setMessage('No verification token provided.');
      }
    };

    verifyEmail();
  }, [location, navigate]);

  return (
    <div>
      <Navbar />
      <div className="container text-center mt-5">
        <h1><Translate textKey="Email Verification" /></h1>
        <p>{message}</p>
      </div>
    </div>
  );
};

export default VerifyPage;
