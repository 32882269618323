import React from 'react';
import ResetPassword from '../components/ResetPassword'; // Import the ResetPassword component

const ResetPasswordPage: React.FC = () => {
  return (
    <div className="container d-flex justify-content-center align-items-center vh-100">
      <div className="card p-4 shadow-lg w-100" style={{ maxWidth: '400px' }}>
        <h2 className="text-center mb-4">Reset Password</h2>
        <ResetPassword />
      </div>
    </div>
  );
};

export default ResetPasswordPage;
