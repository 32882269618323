// src/pages/PermissionsPage.tsx
import React, { useState } from 'react';
import Navbar from '../components/Navbar';
import UserPermissions from '../components/UserPermissions';

const PermissionsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<'userPermissions'>('userPermissions');

  return (
    <div>
      <Navbar />
      <div className="container">
      <h1  style={{ paddingTop: '20px' }}>Permissions</h1>
        
        

        {/* Render Selected Component */}
        {selectedTab === 'userPermissions' && <UserPermissions />}
      </div>
    </div>
  );
};

export default PermissionsPage;
