// src/page/UnverifiedPage.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';

const UnverifiedPage: React.FC = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem('token'); // Remove token from localStorage
    navigate('/login'); // Redirect to login page
  };

  return (
    <div className="container text-center mt-5">
      <h1>Please wait for verification from the manager.</h1>
      <button className="btn btn-danger mt-4" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
};

export default UnverifiedPage;
