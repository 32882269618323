import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Modal, Button, Dropdown, DropdownButton } from 'react-bootstrap';
import Translate from './Lang';

interface User {
  Id: number;
  Username: string;
  Role: string;
  IsApproved: boolean;
  Note: string;
}

const UserPermissions: React.FC = () => {
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editedNote, setEditedNote] = useState<string>('');
  const [selectedUserId, setSelectedUserId] = useState<number | null>(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('/api/users');
        setUsers(response.data);
      } catch (err) {
        setError('Failed to fetch users');
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleRoleChange = async (userId: number, newRole: string) => {
    try {
      await axios.patch(`/api/users/change-role`, { userId, role: newRole });
      setUsers(users.map(user => user.Id === userId ? { ...user, Role: newRole } : user));
    } catch (err) {
      setError('Failed to update user role');
    }
  };

  const handleDelete = async (userId: number) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this user?');
    if (!confirmDelete) return;

    try {
      await axios.delete(`/api/users/${userId}`);
      setUsers(users.filter(user => user.Id !== userId));
    } catch (err) {
      setError('Failed to delete user');
    }
  };

  const openModal = (userId: number, currentNote: string) => {
    setSelectedUserId(userId);
    setEditedNote(currentNote);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedUserId(null);
    setEditedNote('');
  };

  const saveNoteChange = async () => {
    if (selectedUserId === null) return;

    try {
      await axios.patch(`/api/users/${selectedUserId}/note`, { note: editedNote });
      setUsers(users.map(user => user.Id === selectedUserId ? { ...user, Note: editedNote } : user));
      closeModal();
    } catch (err) {
      setError('Failed to update note');
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div>
      <table className="custom-table">
        <thead className="custom-thead">
          <tr>
            <th className="custom-th">Email</th>
            <th className="custom-th"><Translate textKey="Role" /></th>
            <th className="custom-th"><Translate textKey="Note" /></th>
            <th className="custom-th"><Translate textKey="Action" /></th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user.Id} className="custom-td">
              <td>{user.Username}</td>
              <td>
                {/* Dropdown for role selection, disabled if role is 'admin' */}
                <DropdownButton
                  title={user.Role}
                  variant="secondary"
                  onSelect={(eventKey) => handleRoleChange(user.Id, eventKey || user.Role)}
                  disabled={user.Role === 'admin'}
                >
                  <Dropdown.Item eventKey="user">User</Dropdown.Item>
                  <Dropdown.Item eventKey="verified user">Verified User</Dropdown.Item>
                  <Dropdown.Item eventKey="manager">Manager</Dropdown.Item>
                </DropdownButton>
              </td>
              <td>{user.Note}</td>
              <td>
                <button
                  className="btn btn-danger btn-sm ms-2"
                  onClick={() => handleDelete(user.Id)}
                  disabled={user.Role === 'admin'}
                >
                  <Translate textKey="Delete" />
                </button>
                <button
                  className="btn btn-secondary btn-sm ms-2"
                  onClick={() => openModal(user.Id, user.Note)}
                >
                  <Translate textKey="Edit Note" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Modal for editing note */}
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Note</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input
            type="text"
            value={editedNote}
            onChange={(e) => setEditedNote(e.target.value)}
            className="form-control"
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={saveNoteChange}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserPermissions;
