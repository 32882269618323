import React, { ReactNode } from 'react';
import Translate from './Lang';

interface TileProps {
  title: ReactNode;
  count: number;
  onClick: () => void;
}

const Tile: React.FC<TileProps> = ({ title, count, onClick }) => {
  return (
    <div className="tile">
      <h2>{title}</h2>
      <p>{count}</p>
      <button className="btn btn-primary" onClick={onClick}>
      <Translate textKey="View" />
      </button>
    </div>
  );
};

export default Tile;
