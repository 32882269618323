import React, { useState } from 'react';
import axios from 'axios';
import {
  LineChart,
  BarChart,
  AreaChart,
  Line,
  Bar,
  Area,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts';
import '../App.css';

const ReportComponent: React.FC = () => {
  
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [currency, setCurrency] = useState('RON'); // Add state for selected currency
  const [graphData, setGraphData] = useState<any[]>([]);
  const [total, setTotal] = useState<number | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [chartType, setChartType] = useState<string>('line');

  
  const handleFetchReport = async () => {
    setError(null);
    try {
      const totalResponse = await axios.get('/api/reports/total', {
        params: { StartDate: startDate, EndDate: endDate, currency },
      });

      setTotal(parseFloat((totalResponse.data[currency] || 0).toFixed(2)));

      const dailyResponse = await axios.get('/api/reports/daily', {
        params: { StartDate: startDate, EndDate: endDate, currency },
      });

      setGraphData(dailyResponse.data.map((entry: any) => ({
        date: entry.Date,
        value: parseFloat(entry.Total.toFixed(2)), // Limit to 2 decimal places
      })));
    } catch (err) {
      setError('Failed to fetch report');
      console.error(err);
    }
  };

  

  const formatDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB');
  };

  

  const renderChart = (data: any[], color: string) => {
    switch (chartType) {
      case 'bar':
        return (
          <BarChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={formatDate} />
            <YAxis />
            <Tooltip labelFormatter={(label) => formatDate(label)} />
            <Legend />
            <Bar dataKey="value" fill={color} />
          </BarChart>
        );
      case 'area':
        return (
          <AreaChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={formatDate} />
            <YAxis />
            <Tooltip labelFormatter={(label) => formatDate(label)} />
            <Legend />
            <Area type="monotone" dataKey="value" stroke={color} fill={color} />
          </AreaChart>
        );
      case 'line':
      default:
        return (
          <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={formatDate} />
            <YAxis />
            <Tooltip labelFormatter={(label) => formatDate(label)} />
            <Legend />
            <Line type="monotone" dataKey="value" stroke={color} activeDot={{ r: 8 }} />
          </LineChart>
        );
    }
  };

  return (
    <div className="report-component">
      <div className="date-inputs">
        <label>
          Start Date:
          <input type="date" value={startDate} onChange={(e) => setStartDate(e.target.value)} />
        </label>
        <label>
          End Date:
          <input type="date" value={endDate} onChange={(e) => setEndDate(e.target.value)} />
        </label>
        <label>
          <button onClick={handleFetchReport} className="generate-report-button">Generate Report</button>
        </label>
      </div>

      <div className="chart-settings">
        <label>
          Chart Type:
          <select value={chartType} onChange={(e) => setChartType(e.target.value)}>
            <option value="line">Line Chart</option>
            <option value="bar">Bar Chart</option>
            <option value="area">Area Chart</option>
          </select>
        </label>
        <label>
          Currency:
          <select value={currency} onChange={(e) => setCurrency(e.target.value)}>
            <option value="RON">RON</option>
            <option value="EUR">EUR</option>
          </select>
        </label>
      </div>

      {error && <p className="error-message">{error}</p>}

      {total !== null && (
        <div className="total-amount">
          <h2>Total Amount Invoiced:</h2>
          <p>{total} {currency}</p>
        </div>
      )}

      <div className="chart-container">
        <h3>{currency} Invoiced Amount Over Time</h3>
        <ResponsiveContainer width="100%" height={400}>
          {renderChart(graphData, currency === 'RON' ? '#8884d8' : '#82ca9d')}
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ReportComponent;
